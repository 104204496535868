import RefsStore from "../../../utils/RefsStore";
import translates from "../../../api/modules/translates";

export function copyKeywords(ctx, prop = (i) => {return i.keyword.term}) {
    RefsStore.getRef('copier').copy(
        ctx.checkedItems.map(prop).join("\n")
    );
}

export function copyToClipboard(text) {
    RefsStore.getRef('copier').copy(text);
}

export async function translateAll(ctx) {
    const allTranslated = ctx.allTranslated;
    if (allTranslated) {
        ctx.$refs.table.preparedData.forEach((item) => {
            item.translate = '';
        });
    } else {
        const translatesByKeyword = await translates.translateKeywords(this, ctx.currentApp.store.key, ctx.$refs.table.preparedData.map(item => item.keyword?.term ?? item.keyword));
        ctx.$refs.table.preparedData.forEach((item) => {
            item.translate = translatesByKeyword[item.keyword?.term ?? item.keyword];
        });
    }
}